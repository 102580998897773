import React from "react";
import Home_Hero from "../component/Home_Hero";
import "../stylesheets/home_page.css";
import Greeting_message from "../component/Greeting_message";
import Facilities from "../component/Facilities";
import CheckIn from "../component/CheckIn";

export default function index() {
  const mMessage = "";
  return (
    <React.Fragment>
      <div className="upper-div">
        <Home_Hero />
      </div>
      <div className="mid-div">
        <Greeting_message />
        <Facilities />
      </div>
    
      <CheckIn/>
     
     
    </React.Fragment>
  );
}
