import React from 'react'
import "../stylesheets/navbar.css"

import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function Navigational_Bar 
() {
  return (
    <React.Fragment>
 <Navbar expand="lg" className="bg-body-tertiary">
  <Container>
    <Navbar.Brand href="#home">
      <Image className='logo-img' src="./OSUBI/Logo.jpg" rounded />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ms-auto align-items-center"> {/* Changed me-auto to ms-auto for right alignment */}
        <Nav.Link href="#home" className="px-3"><b>Home</b></Nav.Link>
        <Nav.Link href="#gallery" className="px-3"><b>Gallery</b></Nav.Link>
        <Nav.Link href="#book" className="px-3"><b>Book a room</b></Nav.Link>
        <Nav.Link href="#about" className="px-3"><b>About us</b></Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

    </React.Fragment>
        
    
  )
}






