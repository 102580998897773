import React from 'react'
import { useState } from 'react'
import Typewriter from './TypeWriter'

export default function Greeting_message() {
  
  return (
    <div>
      <h1 className='display-3' style={{color:'#612928', textAlign:'center'}}>
  
   We welcome you. <Typewriter text="We hope you’ll enjoy
your stay." delay={100} infinite />
  </h1>
  
  </div>
  )
}
