import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "../stylesheets/facilities.css";
import Girl from "../assets/girl.jpeg";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
export default function Facilities() {
  return (
    <>
      <Container>
        <h4 className="display-5 my-5 font-bold">Facilities</h4>
        <Row>
          <Col md={6} className="div1">
            <div>
              <Image src="./OSUBI/osubi 1.jpg" className="rounded-lg" fluid />
            </div>
          </Col>
          <Col md={6} className="div2">
            <div>
              <p>
                We have a standard swimmming pool, a barbeque stand where you
                can get our delicious fishes at affordable prices.
              </p>
              <Button>View Gallery</Button>
            </div>
          </Col>
          <Row className="clearfix" style={{ position: "relative" }}>
            <Col md={6} className="div3">
              <div>
                <h3>Let's take your mind off stress</h3>
                <p>
                  We treat you like our only customer, so relax and enjoy
                  yourself. We got you covered.{" "}
                </p>
              </div>
            </Col>
            <Col md={6} className="div4 ">
              <Image src={Girl} className="rounded-lg" fluid />
            </Col>
            
            <FaQuoteLeft style={{ position  : 'absolute', top: '410px', right: '670px' }} />
            <FaQuoteRight style={{ position  : 'absolute', top: '580px', right: '260px' }}/>
        
            <div
              style={{
                width: "380px",
                fontSize: "20px",
                position: "absolute",
                top: "400px",
              }}
            >
              <p
                className="text-warning"
                style={{ lineHeight: "33px", wordSpacing: "10px" }}
              >
                Step into a world reserved for the discerning few—where every
                detail whispers luxury, and every moment is draped in elegance.
                This isn’t just a stay; it’s a statement. Your sanctuary
                awaits."
              </p>
            </div>

           
         
            
          </Row>
        </Row>
      </Container>
    </>
  );
}
