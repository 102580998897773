import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import cartoonHotels from "../assets/cartoon hotels.webp";
import Card from "react-bootstrap/Card";
import HorizontalRule from "./HorizontalRule";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SlCalender } from "react-icons/sl";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
export default function CheckIn() {
  const [startDate, setStartDate] = useState(new Date());
  const[endDate, setEndDate] = useState(new Date());
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="flex items-center border rounded-sm p-1">
      <input
        value={value}
        onClick={onClick}
        ref={ref}
        className="border-0 outline-none flex-grow"
        readOnly
      />
      <SlCalender
        onClick={onClick}
        className="text-white cursor-pointer ml-2"
        style={{ cursor: "pointer" }}
      />
    </div>
  ));
  return (
    <div
      style={{
        marginTop: "400px",
        backgroundColor: "white",
        height: "130vh",
        width: "100%",
      }}
    >
      <Container fluid="md">
  <Row className="flex-column flex-md-row g-3"> {/* Added gutter spacing */}
    {/* Left Column - Hotel Info */}
    <Col md={6} className="mt-2">
      <Card body className="h-100"> {/* Full height card */}
        <div className="d-flex flex-column h-100">
          <div className="mt-3 mt-md-5 text-center">
            <h4 className="fw-bold" style={{ color: "#0C0950", fontSize: "clamp(18px, 2vw, 24px)" }}>
              Hotel Booking Form
            </h4>
            <p className="px-3 px-md-5 mt-3 mb-4 mb-md-0">
              Please place online reservation at least 3 days in advance
              by simply completing the form and request booking to us.
            </p>
          </div>
          
          <div className="mt-auto text-center"> {/* Pushes image to bottom */}
            <img 
              src={cartoonHotels} 
              alt="Hotel illustration" 
              className="img-fluid w-75 w-md-100" 
              style={{ maxWidth: "400px" }}
            />
          </div>
        </div>
      </Card>
    </Col>

    {/* Right Column - Booking Form */}
    <Col md={6} className="mt-2">
      <Card body className="h-100" style={{ backgroundColor: "#211C84" }}>
        <div className="text-white">
          {/* Reservation Section */}
          <div className="mt-3 mt-md-5">
            <h6 className="text-warning mb-3">Reservation Information</h6>
            <HorizontalRule />
            
            <div className="mt-4 p-2 p-md-4">
              <Form.Select aria-label="Room selection" size="sm" className="mb-4">
                <option>Select a room</option>
                <option value="1">Classic</option>
                <option value="2">Deluxe</option>
                <option value="3">Suites</option>
              </Form.Select>

              {/* Date/Time Picker Grid */}
              <div className="row g-3 g-md-4"> {/* Responsive grid */}
                {/* Check In */}
                <div className="col-12 col-sm-6">
                  <small className="d-block text-warning mb-1">Check in date</small>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MMMM d, yyyy"
                    customInput={<CustomInput />}
                  />
                </div>
                
                {/* Check Out */}
                <div className="col-12 col-sm-6">
                  <small className="d-block text-warning mb-1">Check out date</small>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="MMMM d, yyyy"
                    customInput={<CustomInput />}
                  />
                </div>
                
                {/* Check In Time */}
                <div className="col-12 col-sm-6">
                  <small className="d-block text-warning mb-1">Check in time</small>
                  <Form.Select size="sm">
                    {[...Array(24)].map((_, i) => (
                      <option key={i} value={i}>
                        {`${i.toString().padStart(2, '0')}:00`}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                
                {/* Check Out Time */}
                <div className="col-12 col-sm-6">
                  <small className="d-block text-warning mb-1">Number of guest(s)</small>
                  <Form.Select size="sm">
                  
                     <option value="1">1</option>
                     <option value="2">2</option>
                  </Form.Select>
                </div>
              </div>
            </div>
          </div>

          {/* Personal Information Section */}
          <div className="mt-4">
            <h6 className="text-warning mb-3">Personal Information</h6>
            <HorizontalRule />
            
            <div className="row g-3 p-2 p-md-3">
              <div className="col-12 col-sm-6">
                <small className="text-warning d-block mb-1">First name</small>
                <Form.Control type="text" size="sm" />
              </div>
              
              <div className="col-12 col-sm-6">
                <small className="text-warning d-block mb-1">Last name</small>
                <Form.Control type="text" size="sm" />
              </div>
              
              <div className="col-12 col-sm-6">
                <small className="text-warning d-block mb-1">
                  Phone <FaPhone className="ms-1" />
                </small>
                <Form.Control type="tel" size="sm" />
              </div>
              
              <div className="col-12 col-sm-6">
                <small className="text-warning d-block mb-1">
                  Email <MdEmail className="ms-1" />
                </small>
                <Form.Control type="email" size="sm" />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="text-center mt-4 mt-md-5 p-3">
            <Button variant="warning" className="px-5">
              Request Booking
            </Button>
          </div>
        </div>
      </Card>
    </Col>
  </Row>
</Container>
    </div>
  );
}
